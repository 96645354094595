import React, { Component } from "react"

import Glide from "@glidejs/glide";

import Answear from "../images/icons/clients/answear.svg";
import MediaMarktGray from "../images/icons/clients/mediamarkt-gray.svg";
import MediaMarktColor from "../images/icons/clients/mediamarkt-color.svg";
import INGGray from "../images/icons/clients/ing-gray.svg";
import INGColor from "../images/icons/clients/ing-color.svg";
import LeroyMerlinGray from "../images/icons/clients/leroymerlin-gray.svg";
import LeroyMerlinColor from "../images/icons/clients/leroymerlin-color.svg";
import MitsubishiGray from "../images/icons/clients/mitsubishimotors-gray.svg";
import MitsubishiColor from "../images/icons/clients/mitsubishimotors-color.svg";
import NationalGeographicGray from "../images/icons/clients/nationalgeographic-gray.svg";
import NationalGeographicColor from "../images/icons/clients/nationalgeographic-color.svg";
import TUIGray from "../images/icons/clients/tui-gray.svg";
import TUIColor from "../images/icons/clients/tui-color.svg";
import CCCGray from "../images/icons/clients/ccc-gray.svg";
import CCCColor from "../images/icons/clients/ccc-color.svg";
import ForF from "../images/icons/clients/forf.svg";

class ClientCarousel extends Component {
  componentDidMount () {
    new Glide('.glide-c', {
      animationDuration: 1000,
      animationTimingFunc: 'ease-in-out',
      autoplay: 4000,
      bound: true,
      breakpoints: {
        400: {
          perView: 1
        },
        800: {
          perView: 2
        },
        1100: {
          perView: 3
        },
        1400: {
          perView: 4
        }
      },
      hoverpause: false,
      keyboard: false,
      perView: 6,
      rewind: true,
      type: 'carousel'
    }).mount();
  }

  render () {
    return (
      <>
        <div className="clientCarousel pad-t-3 pad-b-3">
          <div class="glide-c glide">
            <div class="clientCarousel__track glide__track" data-glide-el="track">
              <ul class="glide__slides">
                <li class="clientCarousel__slide glide__slide">
                  <Answear />
                  <Answear />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <INGGray />
                  <INGColor />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <LeroyMerlinGray />
                  <LeroyMerlinColor />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <MediaMarktGray />
                  <MediaMarktColor />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <MitsubishiGray />
                  <MitsubishiColor />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <NationalGeographicGray />
                  <NationalGeographicColor />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <TUIGray />
                  <TUIColor />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <CCCGray />
                  <CCCColor />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <ForF />
                  <ForF />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ClientCarousel
